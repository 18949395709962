import React from "react";
import { hours } from "./utils";
import propTypes from "prop-types";

export function EmptyHour({ height }) {
  return (
    <div
      style={{ height: height }}
      className="l mr-4 text-white select-none min-h-[82px] "
    >
      00:00
    </div>
  );
}

export function HoursArray() {
  return (
    <div className="  min-h-[82px] ">
      {hours.map((hr) => (
        <div key={hr} className="min-h-[82px] w-full relative">
          <span className="mr-4">{hr}</span>
          <div className="absolute inset-0 grid grid-rows-4">
            {/* <div className="w-full "></div>
            <div className="w-full border-t border-dashed border-gray-300 "></div>
            <div className="w-full border-t border-dashed border-gray-300 "></div>
            <div className="w-full border-t border-dashed border-gray-300 "></div> */}
          </div>
        </div>
      ))}
    </div>
  );
}

EmptyHour.propTypes = {
  week_view: propTypes.bool,
  height: propTypes.string,
};

HoursArray.propTypes = {
  week_view: propTypes.bool,
};
