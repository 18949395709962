import React from "react";
import Event from "./event";
import { hours } from "./utils";
import propTypes from "prop-types";

export function LongEvents({ events, date, day_view, week_view, refs }) {
  return (
    <div
      ref={refs}
      className={` border-solid border-primary-400 border-t border-l pr-px
      ${day_view && "border-r"} 
      ${week_view ? "w-full" : "rounded-[4px] basis"} min-h-[82px] `}
    >
      {events.map((event) => (
        <Event key={event.id} timeless={true} event={event} date={date} />
      ))}
    </div>
  );
}

export function SingleEvents({
  events = [],
  date,
  day_view,
  week_view,
  index,
}) {
  return (
    <div
      className={`relative border-solid border-primary-400 border-l w-full  z-[60]
      ${day_view && "border-r"} 
      ${week_view ? (index ? "border-r" : "") : ""}
      ${week_view ? "w-full" : "rounded-[4px] basis"} min-h-[82px] `}
    >
      {hours.map((hr, ind) => {
        return (
          <div
            key={hr}
            className={` border-solid border-primary-400
              ${ind === 0 ? "border-t" : ""}  
              min-h-[82px]   border-b `}
          ></div>
        );
      })}
      {events.map((eventsArr) =>
        eventsArr.map((event, ind) => (
          <Event
            key={event.id}
            event={event}
            date={date}
            ind={ind}
            length={eventsArr.length}
            week_view={week_view}
          />
        ))
      )}
    </div>
  );
}

LongEvents.propTypes = {
  events: propTypes.array,
  date: propTypes.object,
  day_view: propTypes.bool,
  week_view: propTypes.bool,
  index: propTypes.bool,
  refs: propTypes.object,
};

SingleEvents.propTypes = {
  events: propTypes.array,
  date: propTypes.object,
  day_view: propTypes.bool,
  week_view: propTypes.bool,
  index: propTypes.bool,
};
