import React, { useState, useEffect } from "react";
import { ReactComponent as ArrowLeft } from "../../../assets/icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../../../assets/icons/arrow-right.svg";
import {
  getDaysInWeek,
  calculateWeeklyLeftPosition,
  calculateWeeklyTop,
  getWidth,
  getMaxWidthForWeek,
  getAndChangeEventsFormat,
} from "./utils";
import { EmptyHour, HoursArray } from "./hours";
import { SingleEvents } from "./events";
import propTypes from "prop-types";
import "./calendar.css";
import moment from "moment";
import Event from "./event";
import { useSelector } from "react-redux";

function WeekView({ events, setMonth, setYear, setSelectedDate }) {
  const [longEvents, setLongEvents] = useState([]);
  const [singleEvents, setSingleEvents] = useState([]);
  const selectedDate = useSelector((state) => state.calendar.selectedDate);
  const [week, setWeek] = useState(
    getDaysInWeek(moment(selectedDate).startOf("day"))
  );

  const changeWeek = (num) => {
    setSelectedDate(moment(selectedDate).add(num, "days"));
  };
  const addWeek = () => {
    changeWeek(7);
  };
  const subtractWeek = () => {
    changeWeek(-7);
  };

  const setMonthAndYear = (firstDayOfWeek, lastDayOfWeek) => {
    if (firstDayOfWeek.month() !== lastDayOfWeek.month()) {
      let month =
        firstDayOfWeek.format("MMM") + " - " + lastDayOfWeek.format("MMM");
      let year = lastDayOfWeek.format("YYYY");
      setMonth(month);
      setYear(year);
    } else {
      setMonth(lastDayOfWeek.format("MMMM"));
      setYear(lastDayOfWeek.format("YYYY"));
    }
  };

  function mergeArrays(arr1, arr2) {
    const map = new Map();
    const mergedArray = [];

    for (const obj of arr1) {
      const key = obj.id;
      if (!map.has(key)) {
        map.set(key, true);
        mergedArray.push(obj);
      }
    }

    for (const obj of arr2) {
      const key = obj.id;
      if (!map.has(key)) {
        map.set(key, true);
        mergedArray.push(obj);
      }
    }

    return mergedArray;
  }

  useEffect(() => {
    let new_week = getDaysInWeek(selectedDate);
    setMonthAndYear(new_week[0], new_week[6]);
    let longEvents = [];
    let singleEvents = {};
    setWeek(new_week);
    new_week.forEach((d) => {
      const { _longEvents, _singleEvents } = getAndChangeEventsFormat(
        d,
        events
      );
      longEvents = mergeArrays(longEvents, _longEvents);
      singleEvents[d.date()] = _singleEvents;
    });
    setSingleEvents(singleEvents);
    setLongEvents(longEvents);
  }, [selectedDate, events]);

  return (
    <div className="flex flex-col items-center ">
      <div className="flex w-full ">
        <ArrowLeft onClick={subtractWeek} className="cursor-pointer" />
        <div className="flex flex-1 justify-around ">
          {week.map((date) => (
            <p
              key={date._d}
              className=" text-[18px] font-medium text-center whitespace-nowrap mb-6 ml-3 "
            >
              {date.format("ddd, DD")}
            </p>
          ))}
        </div>
        <ArrowRight onClick={addWeek} className="cursor-pointer" />
      </div>
      <div className="flex w-full">
        <div className="">
          <div
            className="relative"
            style={{
              minHeight: "82px",
              height: longEvents.length * 37 + "px",
            }}
          >
            <div className="absolute inset-0 grid grid-rows-4">
              {/* <div className="w-full "></div> */}
              {/* <div className="w-full border-t border-dashed border-gray-300 "></div>
              <div className="w-full border-t border-dashed border-gray-300 "></div>
              <div className="w-full border-t border-dashed border-gray-300 "></div> */}
            </div>
            <EmptyHour />
          </div>
          <HoursArray />
        </div>
        <div className="flex-1 relative ">
          <div className="">
            <div
              className=" grid grid-cols-7 z-50"
              style={{
                minHeight: "82px",
                height: longEvents.length * 37 + "px",
              }}
            >
              {week.map((item, ind) => {
                return (
                  <div
                    key={item + ind}
                    className={` border-solid border-primary-400 border-l border-t z-[60] min-h-[82px] w-full ${
                      ind === 6 && "border-r"
                    } `}
                  />
                );
              })}
            </div>
            <div
              className="w-full absolute top-0 left-0   z-[60]"
              style={{
                minHeight: "82px",
                height: longEvents.length * 37 + "px",
              }}
            >
              <div className="relative">
                {longEvents?.map((event, ind) => {
                  return (
                    <div
                      key={event.id}
                      className="absolute "
                      style={{
                        top: calculateWeeklyTop(ind),
                        left: calculateWeeklyLeftPosition(event, week),
                        width: getWidth(event.start, event.end),
                        maxWidth: getMaxWidthForWeek(event, week),
                      }}
                    >
                      <Event
                        key={event.id}
                        timeless={true}
                        event={event}
                        date={moment(event.start).startOf("day")}
                        week_view={true}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="flex  z-[60]">
            {week.map((date, ind) => {
              return (
                <div key={date} className="w-full">
                  <div className="flex">
                    <SingleEvents
                      events={singleEvents[date.date()]}
                      date={date}
                      week_view={true}
                      index={ind === 6}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="absolute inset-0 grid weekRows">
            <div className="w-full "></div>
            {/* {Array.from({ length: 95 }).map((_, idx) => (
              <div
                key={idx}
                className="w-full border-t border-dashed border-gray-300 "
              ></div>
            ))} */}
            {/* <div className="w-full border-t border-dashed border-gray-300 "></div> */}
            {/* <div className="w-full border-t border-dashed border-gray-300 "></div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

WeekView.propTypes = {
  events: propTypes.array,
  setMonth: propTypes.func,
  setYear: propTypes.func,
  setSelectedMonth: propTypes.func,
  week: propTypes.array,
  setWeek: propTypes.func,
  selectedDate: propTypes.object,
  setSelectedDate: propTypes.func,
};

export default WeekView;
